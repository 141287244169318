import { useTranslation } from 'libs'
import CurrencyFormat from 'react-currency-format'

import type { CurrencyProps } from './types'

export const Currency = ({ price }: CurrencyProps) => {
	if (price.currency === 'ISK' || price.currency === 'SEK' || price.currency == 'DKK')
		return (
			<CurrencyFormat
				value={Math.trunc(+price.amount)}
				displayType={'text'}
				thousandSeparator={'.'}
				decimalSeparator={','}
				suffix={' kr.'}
			/>
		)

	const { i18n } = useTranslation()
	const { language } = i18n

	return (
		<span>
			{price?.currency &&
				new Intl.NumberFormat(language, {
					style: 'currency',
					currency: price?.currency,
					minimumFractionDigits: 0
				}).format(+price.amount)}
		</span>
	)
}
